.box-ticket-picker {
  padding: 2rem;
  border: 0.1rem solid #c2c2c2;
  margin: 2rem 0;
  background-color: #f5f5f5;

  .picker-section {
    .content {
      margin-bottom:2rem;
      position: relative;

      .ui-datepicker-trigger {
        position: absolute;
        right: 1rem;
        top: 3.3rem;
        background: transparent;
        border: 0;

        &:before {
          color: #1979c3;;
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
          font-size: 3rem;
          content: '\f073';
        }

        > span {
          display:none;
        }
      }

      table {
        thead {
          tr {
            background-color: #c2c2c2;

            th {
              &:last-child {
                text-align: right;
              }
            }
          }
        }
        tbody {
          tr {
            cursor:pointer;
            background-color: #fff;

            &.active {
              background-color: #1979c3;
              color: #fff;
            }

            td {
              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

